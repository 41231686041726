<template>
  <VaDropdown :offset="[13, 0]" class="notification-dropdown" stick-to-edges :close-on-content-click="false">
    <template #anchor>
      <VaButton preset="secondary" color="textPrimary" @click="handleButtonClick">
        <VaBadge overlap>
          <template #text> {{ notificationCount }} </template>
          <VaIconNotification class="notification-dropdown__icon" />
        </VaBadge>
      </VaButton>
    </template>
    <VaDropdownContent class="h-full sm:max-w-[500px] sm:h-auto">
      <section class="sm:max-h-[320px] p-4 overflow-auto">
        <VaList class="space-y-1 mb-12">
          <template v-for="item in notificationsWithRelativeTime" :key="item.id">
            <VaListItem class="text-base">
              <VaListItemSection>
                {{ item.description }}
              </VaListItemSection>
            </VaListItem>
          </template>
          <VaListItem v-if="monthlyMessage" class="text-base">
            <VaListItemSection>
              {{monthlyMessage}}
          </VaListItemSection>
          </VaListItem>
          
         
        </VaList>
        
        <VaButton preset="primary" class="w-full" @click="navigateToSettings">
          Gérer les notifications        </VaButton>
      </section>
    </VaDropdownContent>
  </VaDropdown>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import VaIconNotification from '../../../icons/VaIconNotification.vue'
import { useRouter } from 'vue-router'
import Pusher from 'pusher-js'
import { useNotifications } from '../../../../pages/notifications/composables/useNotification'

const { t } = useI18n()
const router = useRouter()

const { allNotifications, countNotifications, fetchLastNotifications, updateNotificationsView } = useNotifications()

const navigateToSettings = () => {
  router.push('/app/settings')
}

const notificationCount = ref(0)
const monthlyMessage = ref(null)


const handleButtonClick = () => {
  updateNotificationsView()
  notificationCount.value = 0
  
}

const notificationsWithRelativeTime = computed(() => {
  const list = allNotifications.value
  return list
})

onMounted(async () => {
  await fetchLastNotifications()

  notificationCount.value = countNotifications.value
  const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  })
  const userData = localStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
  const userId = user.id
  
  const channel = pusher.subscribe(import.meta.env.VITE_PUSHER_CHANNEL)
  channel.bind('time-reached', function (data: any) {
    if (data.userId === userId) {
     
      notificationCount.value = data.notificationData
    }
  })

  channel.bind('monthly-expenses', function (data: any) {
    if (data.userId === userId) {
      console.log("monthly",data)
      notificationCount.value += data.notificationData
      monthlyMessage.value = data.message
    }
  })
})
</script>

<style lang="scss" scoped>
.notification-dropdown {
  cursor: pointer;

  .notification-dropdown__icon {
    position: relative;
    display: flex;
    align-items: center;
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
