import { useUserStore } from '../stores/user-store'; // Ou Pinia si tu utilises Pinia
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';



export const authGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  
  const store = useUserStore(); 
  const haveAccess = store.user.haveAccess; 
  if (!haveAccess && to.name !== 'payments') {
    next({ name: 'payments' });
  } else {
    next();
  }
};
