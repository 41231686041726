import { sleep } from '../../services/utils'
import { Notification } from '../../pages/notifications/types' // Update path and type name as per your project structure
import api from '../../services/api' // Assuming notifications-db.json contains your notifications data

let notifications: Notification[] = []

export type Pagination = {
  page: number
  perPage: number
  total: number
}

export type Sorting = {
  sortBy: keyof Notification | undefined // Adjust to Notification type
  sortingOrder: 'asc' | 'desc' | null
}

export type Filters = {
  isActive: number
  search: string
}

export const initializeNotifications = async () => {
  const Notifs = await api.get(`notificationsSettings`)
  notifications = Notifs.data.data
}

export const getNotification = async (notification: Notification) => {
  await sleep(1000)
  const index = notifications.findIndex((n) => n.id === notification.id)
  return notifications[index]
}

export const addNotification = async (notification: Notification) => {
  await api.post(`createNotification`, notification)
  await sleep(1000)
  notifications.unshift(notification)
  const getNotif = await api.get(`notificationsSettings`)
  notifications = getNotif.data.data
}

export const updateNotification = async (notification: Notification) => {
  await sleep(1000)
  const index = notifications.findIndex((n) => n.id === notification.id)
  const id_notification = notification.id
  const getNotif = await api.patch(`notificationsUpdate/${id_notification}`, notification)
  notifications[index] = getNotif.data.data
}

export const updateAllstatus = async (notification: Notification) => {
  await sleep(1000)
  const getNotif = await api.patch(`updateAllStatus`, notification)
  notifications = getNotif.data.data
}

export const updateLastNotificationsView = async () => {
  const updateNotificationsView = await api.patch(`updateLastNotifications`)
  return updateNotificationsView
}

export const getAllNotifications = async () => {
  const allNotifications = await api.get(`/notifications/all`)
  return allNotifications
}

export const getLastNotifications = async () => {
  const allNotifications = await api.get(`getLastNotifications`)
  return allNotifications
}

export const getAbonnement = async () => {
  const abonnement = await api.get(`subscription-trackers`)
  return abonnement
}

export const removeNotification = async (notification: Notification) => {
  await sleep(1000)
  notifications.splice(
    notifications.findIndex((n) => n.id === notification.id),
    1,
  )
  const id_notification = notification.id
  await api.delete(`deleteNotification/${id_notification}`)
  const getNotif = await api.get(`notificationsSettings`)
  notifications = getNotif.data.data
}
