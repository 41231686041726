import axios from 'axios'

const api = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL, // Laravel API base URL
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true, // Include this if you are using cookies for auth
})

// Ajouter l'intercepteur de requête pour inclure le token dans chaque requête
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    // Gérer les erreurs de requête si nécessaire
    return Promise.reject(error)
  },
)

// Ajouter l'intercepteur de réponse pour gérer les erreurs 401
api.interceptors.response.use(
  (response) => {
    // Si la réponse est réussie, la renvoyer telle quelle
    return response
  },
  (error) => {
    // Vérifier si l'erreur a un code 401
    if (error.response && error.response.status === 401) {
      // Par exemple, rediriger vers la page de login ou rafraîchir le token
      console.log('Erreur 401: Token invalide ou expiré.')

      // Optionnel : Supprimer le token et rediriger l'utilisateur vers la page de connexion
      localStorage.removeItem('token')
      window.location.href = '/login' // Remplacer par la route de ta page de login
    }

    // Renvoyer l'erreur pour que l'appelant puisse la gérer
    return Promise.reject(error)
  },
)

export default api
