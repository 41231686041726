import { defineStore } from 'pinia'
import { ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const user = ref(JSON.parse(localStorage.getItem('user') || '{}'));

  function updateUserName(newName: string) {
    user.value.name = newName;
    localStorage.setItem('user', JSON.stringify(user.value)); 
  }

  function updateFirstname(newName: string) {
    user.value.firstname = newName;
    localStorage.setItem('user', JSON.stringify(user.value)); 
  }

  function updateLastname(newName: string) {
    user.value.lastname = newName;
    localStorage.setItem('user', JSON.stringify(user.value)); 
  }

  function updateTrialEnd(newDate: string) {
    user.value.trialEndAt = newDate;
    localStorage.setItem('user', JSON.stringify(user.value)); 
  }


  function updateUser(values: any) {
    user.value = values;
    localStorage.setItem('user', JSON.stringify(user.value)); 
  }

  return { user, updateUserName,updateLastname ,updateFirstname,updateTrialEnd,updateUser};
});


